import { m } from 'framer-motion';
import { useState, useCallback, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { _notifications } from 'src/_mock';
import SockJS from 'sockjs-client';
import webstomp from 'webstomp-client';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import Label from 'src/components/label';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import Iconify from 'src/components/iconify';
import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
import NotificationItem from './notification-item';

export default function NotificationsPopover() {
  const router = useRouter();

  const drawer = useBoolean();
  const smUp = useResponsive('up', 'sm');
  const [currentTab, setCurrentTab] = useState('unread');
  const [notifications, setNotifications] = useState(() => {
    const storedNotifications = localStorage.getItem('notifications');
    return storedNotifications ? JSON.parse(storedNotifications) : [];
  });
  const { enqueueSnackbar } = useSnackbar();
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  // const totalUnRead = notifications?.filter((item) => item === true).length;
  const totalUnRead = notifications.filter(
    (notification) => notification.status === 'Unread'
  ).length;
  const user = JSON.parse(sessionStorage.getItem('user'));
  const [uniqueNotificationIds, setUniqueNotificationIds] = useState(new Set());
  const [processedNotificationIds, setProcessedNotificationIds] = useState(new Set());

  const TABS = [
    // {
    //   value: 'all',
    //   label: 'All',
    //   count: notifications.length,
    // },
    {
      value: 'unread',
      label: 'Unread',
      count: notifications.filter((notification) => notification.status === 'Unread').length,
    },
    {
      value: 'read',
      label: 'Read',
      count: notifications.filter((notification) => notification.status === 'Read').length,
    },
  ];

  useEffect(() => {
    // Function to filter notifications based on tab
    const filterNotifications = () => {
      switch (currentTab) {
        case 'unread':
          return notifications.filter((notification) => notification.status === 'Unread');
        case 'read':
          return notifications.filter((notification) => notification.status === 'Read');
        default:
          return notifications;
      }
    };

    setFilteredNotifications(filterNotifications());
  }, [currentTab, notifications]);

  const handleNotificationClick = (notification) => {
    // Update status to Read when notification is clicked
    const updatedNotifications = notifications.map((item) =>
      item.id === notification.id ? { ...item, status: 'Read' } : item
    );
    setNotifications(updatedNotifications);

    // Save updated notifications to localStorage
    localStorage.setItem('notifications', JSON.stringify(updatedNotifications));

    if (notification.screenId) {
      if (notification.screenId.screenNm === 'Item Requisition')
        router.push(paths.dashboard.inventory.ItemRequisition);
    }
  };

  const handleNotification = useCallback(
    (message, topic) => {
      const newNotification = JSON.parse(message.body);

      console.log('Notification received for topic:', topic, newNotification);

      if (newNotification.user.userId === user.userId) {
        enqueueSnackbar(`${topic}!`, { variant: 'info', autoHideDuration: 10000 });

        // Check if the notification ID has already been processed
        if (!processedNotificationIds.has(newNotification.id)) {
          // Add the notification ID to the set of processed IDs
          setProcessedNotificationIds((prevIds) => new Set(prevIds).add(newNotification.id));

          // Add the new notification
          setNotifications((prevNotifications) => {
            // Convert ID to string if necessary for comparison
            const notificationIds = prevNotifications.map((notification) =>
              notification.id.toString()
            );
            if (!notificationIds.includes(newNotification.id.toString())) {
              const updatedNotifications = [newNotification, ...prevNotifications];
              localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
              return updatedNotifications;
            }
            return prevNotifications;
          });
        }
      }
    },
    [user, processedNotificationIds, enqueueSnackbar]
  );

  useEffect(() => {
    let stompClient;
    const socket = new SockJS(`${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}`);

    const connectAndSubscribe = () => {
      stompClient = webstomp.over(socket);
      stompClient.connect({}, () => {
        stompClient.subscribe('/topic/newRequisition', (message) => {
          handleNotification(message, 'New Item Requisition');
        });
        stompClient.subscribe('/topic/updtIntervSts', (message) => {
          handleNotification(message, 'Candidate has been selected for position!!');
        });
        stompClient.subscribe('/topic/updtFeedbackGiven', (message) => {
          handleNotification(message, 'Feedback of interview has been updated!!');
        });
        stompClient.subscribe('/topic/feedbackGiven', (message) => {
          handleNotification(message, 'Feedback of interview has been given!!');
        });
        stompClient.subscribe('/topic/interviewScheduled', (message) => {
          handleNotification(message, 'New Interview Scheduled!!');
        });
        stompClient.subscribe('/topic/updtinterviewScheduled', (message) => {
          handleNotification(message, 'Update Scheduled Interview!!');
        });
        // stompClient.subscribe('/topic/newOfferLetter', (message) => {
        //   handleNotification(message, 'Offer Letter Accepted!!');
        // });
        stompClient.subscribe('/topic/rescheduleReqst', (message) => {
          handleNotification(message, 'Sent you request for reschedule interview!!');
        });
        stompClient.subscribe('/topic/newCanRequisition', (message) => {
          handleNotification(message, 'New Candidate Requisition has been raised!!');
        });
        stompClient.subscribe('/topic/ReuseCanRequisition', (message) => {
          handleNotification(message, 'New Candidate Requisition has been raised!!');
        });
        stompClient.subscribe('/topic/ActionOnRequisition', (message) => {
          const n = JSON.parse(message.body);
          handleNotification(message, `${n.title}!`);
        });
        stompClient.subscribe('/topic/ActionOnMatRequisition', (message) => {
          const n = JSON.parse(message.body);
          handleNotification(message, `${n.title}!`);
        });
      });
    };

    connectAndSubscribe();

    return () => {
      if (stompClient && stompClient.connected) {
        stompClient.disconnect();
      }
    };
  }, [handleNotification]);

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications?.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const handleRemoveNotification = (notificationToRemove) => {
    const updatedNotifications = notifications.filter(
      (notification) => notification !== notificationToRemove
    );

    localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
    setNotifications(updatedNotifications);
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>
      {/* {JSON.stringify(notifications)} */}
      {/* {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )} */}

      {!smUp && (
        <IconButton onClick={drawer.onFalse} sx={{ color: 'red' }}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={(_, newValue) => setCurrentTab(newValue)}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'read' && 'success') ||
                'default'
              }
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {filteredNotifications
          // .filter((notification) => notification.user === user) // Filter notifications for the current user
          .map((notification, index) => (
            <NotificationItem
              key={index}
              notification={notification}
              onRemove={handleRemoveNotification}
              onClick={() => handleNotificationClick(notification)}
            />
          ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="mdi:notifications-active" width={24} height={26} color="#ffbe4d" />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
          {/* <IconButton onClick={handleMarkAllAsRead}>
            <Iconify icon="solar:settings-bold-duotone" />
          </IconButton> */}
        </Stack>

        <Divider />

        {renderList}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth size="large">
            View All
          </Button>
        </Box> */}
      </Drawer>
    </>
  );
}
