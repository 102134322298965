import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { fData } from 'src/utils/format-number';
import Cropper from 'react-easy-crop';
import { cropImage } from 'src/utils/cropImage';
import { createFilterOptions } from '@mui/base';
import { Button, InputAdornment, Tooltip } from '@mui/material';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import UserRoleSiteTableView from './view/user-role-site-table-view';

export default function AccountGeneral({ handlerForProfile }) {
  const { enqueueSnackbar } = useSnackbar();
  const token = sessionStorage.getItem('token');
  const user = JSON.parse(sessionStorage.getItem('user'));

  const [profilePic, setProfilePic] = useState(null);
  const [userName, setUserName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [phoneCode, setPhCode] = useState('');
  const [emailAddr, setEmailAddr] = useState('');
  const [countryArr, setCountryArr] = useState([]);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 100, height: 100 });
  const [zoom, setZoom] = useState(1.5); // Increased initial zoom
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropMode, setCropMode] = useState(false);
  const confirm = useBoolean();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null); // New state to store the uploaded file

  const openConfirmDialog = () => {
    setConfirmOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/getProfilePhoto`,
          {
            params: { id: user.userId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log('Response Data:', response.data);
        if (response.status === 200) {
          setProfilePic(response.data);
        } else {
          console.error('Failed to fetch profile paths:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [user.userId, token]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/getUserDetailsForProfile`,
          {
            params: { id: user.userId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log('Response Data for user:', response.data);
        if (response.status === 200) {
          setUserName(response.data.userName);
          setEmailAddr(response.data.email);
          setMobileNumber(response.data.mobileNumber);
          setPhCode(response.data.mobPhoneCode);
        } else {
          console.error('Failed to fetch logo paths:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchUserData();
  }, [user.userId, token]);

  const UpdateUserSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required('Contact Number is required')
      .test('valid-number', 'Please enter a valid contact number.', (value) => {
        console.log('validating', value);

        if (!value) return false;
        const digits = value.toString().replace(/\D/g, '');
        return digits.length >= 10 && digits.length <= 12 && /^(?!0{2,})\d+$/.test(digits);
      })
      .test('phncode-required', 'Country Code is required', (value, context) => {
        // Arrow function
        const { phncode } = context.parent; // Extract phncode from context.parent
        if (!phncode) {
          // If only country code is missing
          return false;
        }
        return true; // If country code is valid
      }),
  });

  const handleCropComplete = useCallback((croppedArea, croppedAreaPixelsParam) => {
    setCroppedAreaPixels(croppedAreaPixelsParam);
  }, []);

  const handleCropImage = async () => {
    try {
      const croppedImage = await cropImage(previewUrl, croppedAreaPixels);

      if (croppedImage) {
        // Convert the cropped image to a File object
        const croppedImageFile = new File([croppedImage], 'cropped-image.jpg', {
          type: 'image/jpeg',
        });

        // Update the avatar field with the cropped image
        setProfilePic(croppedImageFile);

        // Update the preview URL to show the cropped image
        const newPreviewUrl = URL.createObjectURL(croppedImageFile);
        setPreviewUrl(newPreviewUrl);

        // Exit crop mode
        setCropMode(false);

        console.log('Cropped image successfully set to avatar field.');
      } else {
        console.error('Failed to crop image.');
      }
    } catch (error) {
      console.error('Crop failed:', error);
    }
  };

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      // Validate that the file is an image
      if (!file.type.startsWith('image/')) {
        enqueueSnackbar('Only image files are allowed', { variant: 'error' });
        return;
      }

      const previewURL = URL.createObjectURL(file);
      setPreviewUrl(previewURL);
      setCropMode(true);

      // Save the file for future use in cancel logic
      setUploadedFile(file); // Store the uploaded file
    }
  };

  const handleRemovePhoto = async () => {
    try {
      const formData = new FormData();
      formData.append('id', user.userId);

      await axios.put(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/removeProfilePhoto`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setProfilePic(null);
      setPreviewUrl(null);
      enqueueSnackbar('Profile Picture Removed Successfully!');
    } catch (error) {
      console.error('Error removing profile picture:', error);
      enqueueSnackbar('Error removing profile picture');
    }
  };

  const handleCancelCrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file) {
      const fileType = file.type;

      // List of file types that don't need resizing
      const directPreviewTypes = [
        'image/gif',
        'image/svg+xml',
        'image/heic',
        'image/heif',
        'image/tiff',
        'image/x-tiff',
        'image/webp',
        'image/jpeg',
        'image/jpg',
      ];

      if (directPreviewTypes.includes(fileType)) {
        // Set the preview and profilePic for types that don't need resizing
        const previewURL = URL.createObjectURL(file);
        setPreviewUrl(previewURL);
        setProfilePic(file);
      } else {
        const reader = new FileReader();

        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const targetSize = 144; // Final size for the image

            const aspectRatio = img.width / img.height;
            let newWidth;
            let newHeight;

            if (aspectRatio > 1) {
              newWidth = targetSize;
              newHeight = targetSize / aspectRatio;
            } else {
              newHeight = targetSize;
              newWidth = targetSize * aspectRatio;
            }

            // Set the canvas size and draw the resized image
            canvas.width = targetSize;
            canvas.height = targetSize;

            const startX = (targetSize - newWidth) / 2;
            const startY = (targetSize - newHeight) / 2;

            ctx.clearRect(0, 0, targetSize, targetSize);
            ctx.beginPath();
            ctx.arc(targetSize / 2, targetSize / 2, targetSize / 2, 0, 2 * Math.PI);
            ctx.clip();

            // Draw image centered on the canvas
            ctx.drawImage(img, startX, startY, newWidth, newHeight);

            canvas.toBlob(
              (blob) => {
                if (blob) {
                  const resizedFile = new File([blob], file.name, { type: file.type });
                  setProfilePic(resizedFile);

                  const previewURL = URL.createObjectURL(resizedFile);
                  setPreviewUrl(previewURL);
                }
              },
              file.type,
              0.8
            );
          };
          img.src = reader.result;
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const defaultValues = useMemo(
    () => ({
      displayName: userName || '',
      email: emailAddr || '',
      photoURL: profilePic || null,
      phoneNumber: mobileNumber || '',
      phncode: phoneCode || null,
    }),
    [profilePic, userName, emailAddr, mobileNumber, phoneCode]
  );

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    trigger,
    setValue,
  } = methods;

  useEffect(() => {
    if (profilePic || userName || emailAddr || mobileNumber || phoneCode) {
      reset(defaultValues);
    }
  }, [profilePic, userName, emailAddr, mobileNumber, phoneCode, defaultValues, reset]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/canreqext/countries`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCountryArr(response.data);
      } catch (error) {
        console.error('Error fetching countries:', error);
        setCountryArr([]);
      }
    };

    fetchCountries();
  }, [token]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const userData = {
        userName: data.displayName || '',
        email: data.email || '',
        mobileNumber: data.phoneNumber || '',
        mobPhoneCode: data.phncode || null,
      };

      const formData = new FormData();
      formData.append('userD', JSON.stringify(userData));
      if (profilePic) {
        formData.append('profileP', profilePic);
      }
      formData.append('id', user.userId);

      await axios.put(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/updtuserprofile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Save flag to localStorage before reloading
      localStorage.setItem('profileUpdated', 'true');

      handlerForProfile();
      window.location.reload();
    } catch (error) {
      console.error('Error updating user profile:', error);
      window.location.reload();
    }
  });

  // Check for the flag on page load
  useEffect(() => {
    const profileUpdated = localStorage.getItem('profileUpdated');
    if (profileUpdated) {
      enqueueSnackbar('Profile Updated Successfully!');
      // Remove the flag from localStorage
      localStorage.removeItem('profileUpdated');
    }
  }, [enqueueSnackbar]);

  // Add this useEffect to update the form when profilePic changes
  useEffect(() => {
    if (profilePic) {
      methods.setValue('photoURL', profilePic);
    }
  }, [profilePic, methods]);

  const filterPhoneCode = createFilterOptions({
    stringify: (option) => `${option.countryName} (${option.phoneCode})`,
  });

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
            {!cropMode ? (
              <>
                <RHFUploadAvatar
                  name="photoURL"
                  accept="image/*" // Only allow image files
                  maxSize={3000000}
                  onDrop={handleDrop} // This function will handle the dropped files
                  onDelete={handleRemovePhoto}
                  file={previewUrl || profilePic}
                  // preview={previewUrl} // Add this line
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      <br /> Max size: {fData(3000000)}
                    </Typography>
                  }
                />
                <Button
                  variant="soft"
                  color="error"
                  onClick={openConfirmDialog} // Open confirmation dialog on button click
                  disabled={!profilePic}
                >
                  Remove Photo
                </Button>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    position: 'relative',
                    width: 200,
                    height: 200,
                    borderRadius: '50%', // This will make the box circular
                    overflow: 'hidden', // This ensures the cropped image is confined within the circular shape
                    mb: 5,
                  }}
                >
                  <Cropper
                    image={previewUrl}
                    crop={crop}
                    zoom={zoom}
                    aspect={1} // Circular crop
                    cropShape="round" // Ensures round shape
                    showGrid={false} // Optional: Hide grid if not needed
                    zoomWithScroll // Enable scroll-based zooming
                    onCropChange={setCrop}
                    onCropComplete={handleCropComplete}
                    onZoomChange={setZoom}
                    minZoom={0.5} // Adjust for better zoom out range
                    maxZoom={3} // Adjust for zoom-in range
                    objectFit="contain"
                  />
                </Box>
                <Stack direction="row" justifyContent="center" spacing={2}>
                  <LoadingButton variant="contained" onClick={handleCropImage}>
                    Crop
                  </LoadingButton>
                  <LoadingButton
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                      if (uploadedFile) {
                        handleCancelCrop([uploadedFile]); // Use the uploaded file from state
                      }
                      setCropMode(false); // Exit crop mode
                    }}
                  >
                    Cancel
                  </LoadingButton>
                </Stack>
              </>
            )}
            <ConfirmDialog
              open={confirmOpen}
              onClose={closeConfirmDialog}
              title="Remove Photo?"
              content="Are you sure you want to remove the photo permanently?"
              action={
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    handleRemovePhoto(); // Call remove logic here
                    closeConfirmDialog(); // Close dialog after action
                  }}
                >
                  Confirm Remove
                </Button>
              }
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
              }}
            >
              <Tooltip title="You are not allowed to change Username" placement="top-start">
                <span>
                  <RHFTextField
                    name="displayName"
                    label="Username"
                    disabled
                    style={{
                      cursor: 'pointer',
                      // pointerEvents: 'auto', // Ensure the tooltip works even when the field is disabled
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip title="You are not allowed to change Email Address" placement="top-start">
                <span>
                  <RHFTextField
                    name="email"
                    label="Email Address"
                    style={{
                      cursor: 'pointer',
                      pointerEvents: 'auto', // Ensure the tooltip works even when the field is disabled
                    }}
                    disabled
                  />
                </span>
              </Tooltip>
              <RHFTextField
                name="phoneNumber"
                label={
                  <div>
                    Contact Number <span style={{ color: 'red', fontWeight: 'bolder' }}>*</span>
                  </div>
                }
                type="tel"
                inputProps={{ pattern: '[0-9]*' }}
                onWheel={(e) => e.target.blur()}
                InputLabelProps={{ shrink: true }}
                sx={{ width: 310 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component="span" sx={{ color: 'text.disabled' }}>
                        <RHFAutocomplete
                          name="phncode"
                          disableClearable
                          filterOptions={filterPhoneCode}
                          sx={{
                            '& fieldset': { border: 'none' },
                          }}
                          options={countryArr}
                          onChange={(event, value) => {
                            setValue('phncode', value, { shouldValidate: true });
                            trigger('phoneNumber');
                          }}
                          getOptionLabel={(option) => option.phoneCode}
                          componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                          renderOption={(props, option) => (
                            <li {...props} key={option.shortName}>
                              {option.countryName}({option.phoneCode})
                            </li>
                          )}
                        />
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />

              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="soft" color="success" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Stack>

              <Box>
                <Card sx={{ p: 3 }}>
                  <UserRoleSiteTableView />
                </Card>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
AccountGeneral.propTypes = {
  handlerForProfile: PropTypes.func,
};

// -----------------------------------------------------------------------------------------------------------------------------------------

// import * as Yup from 'yup';
// import { useCallback, useEffect, useMemo, useState } from 'react';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import LoadingButton from '@mui/lab/LoadingButton';
// import Checkbox from '@mui/material/Checkbox';
// import PropTypes from 'prop-types';
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import Stack from '@mui/material/Stack';
// import Grid from '@mui/material/Unstable_Grid2';
// import Typography from '@mui/material/Typography';
// import {
//   Button,
//   createFilterOptions,
//   InputAdornment,
//   Paper,
//   Radio,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Tooltip,
// } from '@mui/material';
// import Iconify from 'src/components/iconify';
// import axios from 'axios';
// import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
// import { useSnackbar } from 'src/components/snackbar';
// import FormProvider, {
//   RHFTextField,
//   RHFAutocomplete,
//   RHFUploadAvatar,
// } from 'src/components/hook-form';
// import { fData } from 'src/utils/format-number';
// import { useTable } from 'src/components/table';
// import { ConfirmDialog } from 'src/components/custom-dialog';
// import { useBoolean } from 'src/hooks/use-boolean';
// import UserRoleSiteTableView from './view/user-role-site-table-view';

// export default function AccountGeneral({ handlerForProfile }) {
//   const { enqueueSnackbar } = useSnackbar();
//   const token = sessionStorage.getItem('token');
//   const user = JSON.parse(sessionStorage.getItem('user')); // Parse the user JSON string

//   const [profilePic, setProfilePic] = useState([]);
//   const [selected, setSelected] = useState(false);
//   const [userName, setUserName] = useState('');
//   const [mobileNumber, setMobileNumber] = useState('');
//   const [phoneCode, setPhCode] = useState('');
//   const [emailAddr, setEmailAddr] = useState('');
//   const [countryArr, setCountryArr] = useState([]);
//   const [previewUrl, setPreviewUrl] = useState(null);
//   const confirm = useBoolean();
//   const [confirmOpen, setConfirmOpen] = useState(false);
//   const openConfirmDialog = () => {
//     setConfirmOpen(true);
//   };

//   const closeConfirmDialog = () => {
//     setConfirmOpen(false);
//   };

//   const handleSelectRow = () => {
//     setSelected(!selected);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/getProfilePhoto`,
//           {
//             params: { id: user.userId },
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         console.log('Response Data:', response.data);
//         if (response.status === 200) {
//           setProfilePic(response.data);
//         } else {
//           console.error('Failed to fetch profile paths:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error:', error);
//       }
//     };

//     fetchData();
//   }, [user.userId, token]);

//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const response = await axios.get(
//           `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/getUserDetailsForProfile`,
//           {
//             params: { id: user.userId },
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         console.log('Response Data for user:', response.data);
//         if (response.status === 200) {
//           setUserName(response.data.userName);
//           setEmailAddr(response.data.email);
//           setMobileNumber(response.data.mobileNumber);
//           setPhCode(response.data.mobPhoneCode);
//         } else {
//           console.error('Failed to fetch logo paths:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error:', error);
//       }
//     };

//     fetchUserData();
//   }, [user.userId, token]);

//   const UpdateUserSchema = Yup.object().shape({
//     phoneNumber: Yup.string()
//     .required('Contact Number is required')
//     .test('valid-number', 'Please enter a valid mobile number.', (value) => {
//       console.log("validating", value);

//       if (!value) return false;
//       const digits = value.toString().replace(/\D/g, '');
//       return digits.length >= 10 && digits.length <= 12 && /^(?!0{2,})\d+$/.test(digits);
//     })
//   });
//   const defaultValues = useMemo(
//     () => ({
//       displayName: userName || '',
//       email: emailAddr || '',
//       photoURL: profilePic,
//       phoneNumber: mobileNumber || '',
//       phncode: phoneCode || null,
//     }),
//     [profilePic, userName, emailAddr, mobileNumber, phoneCode]
//   );

//   const methods = useForm({
//     resolver: yupResolver(UpdateUserSchema),
//     defaultValues,
//   });

//   const {
//     reset,
//     handleSubmit,
//     formState: { isSubmitting },
//   } = methods;

//   useEffect(() => {
//     if (profilePic || userName || emailAddr || mobileNumber || phoneCode) {
//       reset(defaultValues);
//     }
//   }, [profilePic, userName, emailAddr, mobileNumber, phoneCode, defaultValues, reset]);

//   console.log('profile pic:', profilePic);

//   useEffect(() => {
//     const fetchCountries = async () => {
//       try {
//         const response = await axios.get(
//           `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/canreqext/countries`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setCountryArr(response.data);
//       } catch (error) {
//         console.error('Error fetching countries:', error);
//         setCountryArr([]);
//       }
//     };

//     fetchCountries();
//   }, [token]);

//   const handleDrop = (acceptedFiles) => {
//     const file = acceptedFiles[0];
//     if (file) {
//       const fileType = file.type;

//       // List of file types that don't need resizing
//       const directPreviewTypes = [
//         'image/gif',
//         'image/svg+xml',
//         'image/heic',
//         'image/heif',
//         'image/tiff',
//         'image/x-tiff',
//         'image/webp',
//         'image/jpeg',
//         'image/jpg',
//         // 'image/jfif',
//       ];

//       if (directPreviewTypes.includes(fileType)) {
//         // Directly set the preview and profilePic for SVG, GIF, HEIC, TIFF, WEBP, JPEG, JFIF files
//         const previewURL = URL.createObjectURL(file);
//         setPreviewUrl(previewURL);
//         setProfilePic(file);
//       } else {
//         const reader = new FileReader();

//         reader.onload = () => {
//           const img = new Image();
//           img.onload = () => {
//             try {
//               const canvas = document.createElement('canvas');
//               const ctx = canvas.getContext('2d');
//               const targetSize = 144; // Use the same size for width and height

//               const aspectRatio = img.width / img.height;

//               let newWidth;
//               let newHeight;
//               if (aspectRatio > 1) {
//                 newWidth = targetSize;
//                 newHeight = targetSize / aspectRatio;
//               } else {
//                 newHeight = targetSize;
//                 newWidth = targetSize * aspectRatio;
//               }

//               // Set the canvas dimensions
//               canvas.width = targetSize;
//               canvas.height = targetSize;

//               // Calculate the starting coordinates to center the image
//               const startX = (targetSize - newWidth) / 2;
//               const startY = (targetSize - newHeight) / 2;

//               // Clear the canvas
//               ctx.clearRect(0, 0, targetSize, targetSize);

//               // Draw a circular clipping path
//               ctx.beginPath();
//               ctx.arc(targetSize / 2, targetSize / 2, targetSize / 2, 0, 2 * Math.PI);
//               ctx.clip();

//               // Draw the image on the canvas, centered
//               ctx.drawImage(img, startX, startY, newWidth, newHeight);

//               canvas.toBlob(
//                 (blob) => {
//                   if (blob) {
//                     const resizedFile = new File([blob], file.name, { type: file.type });
//                     setProfilePic(resizedFile);

//                     const previewURL = URL.createObjectURL(resizedFile);
//                     setPreviewUrl(previewURL);
//                   } else {
//                     console.error('Failed to convert canvas to blob');
//                   }
//                 },
//                 file.type,
//                 0.8
//               );
//             } catch (error) {
//               console.error('Canvas processing error:', error);
//             }
//           };
//           img.onerror = () => {
//             console.error('Image loading error');
//           };
//           img.src = reader.result;
//         };

//         reader.onerror = () => {
//           console.error('File reading error');
//         };
//         reader.readAsDataURL(file);
//       }
//     } else {
//       console.log('No file selected');
//     }
//   };

//   const handleRemovePhoto = async () => {
//     try {
//       const formData = new FormData();
//       formData.append('id', user.userId);

//       await axios.put(
//         `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/removeProfilePhoto`,
//         formData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'multipart/form-data',
//           },
//         }
//       );

//       setProfilePic(null);
//       setPreviewUrl(null);
//       enqueueSnackbar('Profile Picture Removed Successfully!');
//     } catch (error) {
//       console.error('Error removing profile picture:', error);
//       enqueueSnackbar('Error removing profile picture');
//     }
//   };

//   const filterPhoneCode = createFilterOptions({
//     stringify: (option) => `${option.countryName} (${option.phoneCode})`,
//   });

//   const onSubmit = handleSubmit(async (data) => {
//     try {
//       const userData = {
//         userName: data.displayName || '',
//         email: data.email || '',
//         mobileNumber: data.phoneNumber || '',
//         mobPhoneCode: data.phncode || null,
//       };

//       const formData = new FormData();
//       formData.append('userD', JSON.stringify(userData));
//       if (profilePic) {
//         formData.append('profileP', profilePic);
//       }
//       formData.append('id', user.userId);

//       await axios.put(
//         `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/updtuserprofile`,
//         formData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'multipart/form-data',
//           },
//         }
//       );

//       // Save flag to localStorage before reloading
//       localStorage.setItem('profileUpdated', 'true');

//       handlerForProfile();
//       window.location.reload();
//     } catch (error) {
//       console.error('Error updating user profile:', error);
//       window.location.reload();
//     }
//   });

//   // Check for the flag on page load
//   useEffect(() => {
//     const profileUpdated = localStorage.getItem('profileUpdated');
//     if (profileUpdated) {
//       enqueueSnackbar('Profile Updated Successfully!');
//       // Remove the flag from localStorage
//       localStorage.removeItem('profileUpdated');
//     }
//   }, [enqueueSnackbar]);

//   return (
//     <FormProvider methods={methods} onSubmit={onSubmit}>
//       <Grid container spacing={3}>
//         <Grid item xs={12} md={4}>
//           <Card
//             sx={{
//               pt: 5,
//               pb: 5,
//               px: 3,
//               textAlign: 'center',
//               flexDirection: 'column',
//               alignItems: 'center',
//               justifyContent: 'center',
//             }}
//           >
//             <RHFUploadAvatar
//               name="photoURL"
//               maxSize={3000000}
//               onDrop={handleDrop}
//               file={previewUrl || profilePic}
//               // file={profilePic}
//               helperText={
//                 <Typography
//                   variant="caption"
//                   sx={{
//                     mt: 3,
//                     mx: 'auto',
//                     display: 'block',
//                     textAlign: 'center',
//                     color: 'text.disabled',
//                   }}
//                 >
//                   Max size: {fData(3000000)}
//                   <br />
//                 </Typography>
//               }
//             />
//             <Button
//               variant="soft"
//               color="error"
//               onClick={openConfirmDialog} // Open confirmation dialog on button click
//               disabled={!profilePic}
//             >
//               Remove Photo
//             </Button>

//             <ConfirmDialog
//               open={confirmOpen}
//               onClose={closeConfirmDialog}
//               title="Remove Photo?"
//               content="Are you sure you want to remove the photo permanently?"
//               action={
//                 <Button
//                   variant="contained"
//                   color="error"
//                   onClick={() => {
//                     handleRemovePhoto(); // Call remove logic here
//                     closeConfirmDialog(); // Close dialog after action
//                   }}
//                   // disabled={!profilePic}
//                 >
//                   Confirm Remove
//                 </Button>
//               }
//             />
//           </Card>
//         </Grid>
//         <Grid item xs={12} md={8}>
//           <Card sx={{ p: 3 }}>
//             <Box
//               rowGap={3}
//               columnGap={2}
//               display="grid"
//               gridTemplateColumns={{
//                 xs: 'repeat(1, 1fr)',
//                 sm: 'repeat(1, 1fr)',
//               }}
//             >
//               <Tooltip title="You are not allowed to change Username" placement="top-start">
//                 <span>
//                   <RHFTextField
//                     name="displayName"
//                     label="Username"
//                     disabled
//                     style={{
//                       cursor: 'pointer',
//                       // pointerEvents: 'auto', // Ensure the tooltip works even when the field is disabled
//                     }}
//                   />
//                 </span>
//               </Tooltip>
//               <Tooltip title="You are not allowed to change Email Address" placement="top-start">
//                 <span>
//                   <RHFTextField
//                     name="email"
//                     label="Email Address"
//                     style={{
//                       cursor: 'pointer',
//                       pointerEvents: 'auto', // Ensure the tooltip works even when the field is disabled
//                     }}
//                     disabled
//                   />
//                 </span>
//               </Tooltip>
//               <RHFTextField
//                 name="phoneNumber"
//                 label={
//                   <div>
//                     Contact Number <span style={{ color: 'red', fontWeight: 'bolder' }}>*</span>
//                   </div>
//                 }
//                 type="tel"
//                 inputProps={{ pattern: "[0-9]*" }}
//                 onWheel={(e) => e.target.blur()}
//                 InputLabelProps={{ shrink: true }}
//                 sx={{ width: 310 }}
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">
//                       <Box component="span" sx={{ color: 'text.disabled' }}>
//                         <RHFAutocomplete
//                           name="phncode"
//                           disableClearable
//                           filterOptions={filterPhoneCode}
//                           sx={{
//                             '& fieldset': { border: 'none' },
//                           }}
//                           options={countryArr}
//                           getOptionLabel={(option) => option.phoneCode}
//                           componentsProps={{ popper: { style: { width: 'fit-content' } } }}
//                           renderOption={(props, option) => (
//                             <li {...props} key={option.shortName}>
//                               {option.countryName}({option.phoneCode})
//                             </li>
//                           )}
//                         />
//                       </Box>
//                     </InputAdornment>
//                   ),
//                 }}
//               />

//               <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
//                 <LoadingButton type="submit" variant="soft" color="success" loading={isSubmitting}>
//                   Save Changes
//                 </LoadingButton>
//               </Stack>

//               <Box>
//                 <Card sx={{ p: 3 }}>
//                   <UserRoleSiteTableView />
//                 </Card>
//               </Box>
//             </Box>
//           </Card>
//         </Grid>
//       </Grid>
//     </FormProvider>
//   );
// }

// AccountGeneral.propTypes = {
//   handlerForProfile: PropTypes.func,
// };

// -------------------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------------------

// import * as Yup from 'yup';
// import { useCallback, useEffect, useMemo, useState } from 'react';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import LoadingButton from '@mui/lab/LoadingButton';
// import Checkbox from '@mui/material/Checkbox';
// import PropTypes from 'prop-types';
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import Stack from '@mui/material/Stack';
// import Grid from '@mui/material/Unstable_Grid2';
// import Typography from '@mui/material/Typography';
// import {
//   createFilterOptions,
//   InputAdornment,
//   Paper,
//   Radio,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from '@mui/material';
// import Iconify from 'src/components/iconify';
// import axios from 'axios';
// import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
// import { useSnackbar } from 'src/components/snackbar';
// import FormProvider, {
//   RHFTextField,
//   RHFAutocomplete,
//   RHFUploadAvatar,
// } from 'src/components/hook-form';
// import { fData } from 'src/utils/format-number';
// import { useTable } from 'src/components/table';

// export default function AccountGeneral(onSelectRow) {
//   const { enqueueSnackbar } = useSnackbar();
//   const [countryArr, setCountryArr] = useState([]);
//   const token = sessionStorage.getItem('token');
//   const user = JSON.parse(sessionStorage.getItem('user')); // Parse the user JSON string

//   const [profilePic, setProfilePic] = useState([]);
//   const [selected, setSelected] = useState(false);
//   const [userName, setUserName] = useState();
//   const [mobileNumber, setMobileNumber] = useState();
//   const [phoneCode, setPhCode] = useState();
//   const [emailAddr, setEmailAddr] = useState();

//   const handleSelectRow = () => {
//     setSelected(!selected);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/getProfilePhoto`,
//           {
//             params: { id: user.userId },
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         console.log('Response Data:', response.data);
//         if (response.status === 200) {
//           setProfilePic(response.data);
//         } else {
//           console.error('Failed to fetch logo paths:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error:', error);
//       }
//     };

//     fetchData();
//   }, [user.userId, token]);

//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const response = await axios.get(
//           `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/getUserDetailsForProfile`,
//           {
//             params: { id: user.userId },
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         console.log('Response Data for user:', response.data);
//         if (response.status === 200) {
//           setUserName(response.data.userName);
//           setEmailAddr(response.data.email);
//           setMobileNumber(response.data.mobileNumber);
//           setPhCode(response.data.mobPhoneCode);
//         } else {
//           console.error('Failed to fetch logo paths:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error:', error);
//       }
//     };

//     fetchUserData();
//   }, [user.userId, token]);

//   const UpdateUserSchema = Yup.object().shape({
//     // displayName: Yup.string().required('Name is required'),
//     phoneNumber: Yup.string()
//       .required('Contact Number is required')
//       .max(12, 'Enter valid mobile number.'),
//     // email: Yup.string().required('Email is required').email('Email must be a valid email address'),
//     // photoURL: Yup.mixed().nullable().required('Avatar is required'),
//   });

//   const defaultValues = useMemo(
//     () => ({
//       displayName: userName || '',
//       email: emailAddr || '',
//       photoURL: profilePic,
//       phoneNumber: mobileNumber || '',
//       phncode: phoneCode || null,
//     }),
//     [profilePic, userName, emailAddr, mobileNumber, phoneCode]
//   );

//   const methods = useForm({
//     resolver: yupResolver(UpdateUserSchema),
//     defaultValues,
//   });

//   const {
//     reset,
//     setValue,
//     handleSubmit,
//     formState: { isSubmitting },
//   } = methods;

//   useEffect(() => {
//     if (profilePic || userName || emailAddr || mobileNumber || phoneCode) {
//       reset(defaultValues);
//     }
//   }, [profilePic, userName, emailAddr, mobileNumber, phoneCode, defaultValues, reset]);

//   console.log('profile pic:', profilePic);

//   useEffect(() => {
//     const fetchCountries = async () => {
//       try {
//         const response = await axios.get(
//           `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/canreqext/countries`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setCountryArr(response.data);
//       } catch (error) {
//         console.error('Error fetching countries:', error);
//         setCountryArr([]);
//       }
//     };

//     fetchCountries();
//   }, [token]);

//   const [sites, setSites] = useState([]);
//   const [role, setRole] = useState([]);

//   const fetchSites = useCallback(async () => {
//     try {
//       const response = await axios.post(
//         `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/userrole/getbyuser`,
//         user, // Pass the user object in the request body
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setSites(response.data.map((item) => item.subscrSite.subscrSiteName)); // Assuming the API response is an array of site objects
//       setRole(response.data.map((item) => item.roleId.roleName));
//     } catch (error) {
//       console.error('Error fetching sites:', error);
//     }
//   }, [token, user]);

//   useEffect(() => {
//     if (user) {
//       fetchSites();
//       // eslint-disable-next-line react-hooks/exhaustive-deps
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   // const handleDrop = (acceptedFiles) => {
//   //   const file = acceptedFiles[0];

//   //   if (file) {
//   //     const newFile = Object.assign(file, {
//   //       preview: URL.createObjectURL(file),
//   //     });

//   //     setValue('photoURL', newFile, { shouldValidate: true });
//   //   }
//   // };

//   const [previewUrl, setPreviewUrl] = useState(null);

//   const handleDrop = (acceptedFiles) => {
//     const file = acceptedFiles[0];

//     if (file) {
//       const reader = new FileReader();

//       reader.onload = () => {
//         const img = new Image();
//         img.onload = () => {
//           const canvas = document.createElement('canvas');
//           const ctx = canvas.getContext('2d');
//           const targetSize = 144; // Use the same size for width and height

//           const aspectRatio = img.width / img.height;

//           let newWidth;
//           let newHeight;
//           if (aspectRatio > 1) {
//             newWidth = targetSize;
//             newHeight = targetSize / aspectRatio;
//           } else {
//             newHeight = targetSize;
//             newWidth = targetSize * aspectRatio;
//           }

//           // Set the canvas dimensions
//           canvas.width = targetSize;
//           canvas.height = targetSize;

//           // Calculate the starting coordinates to center the image
//           const startX = (targetSize - newWidth) / 2;
//           const startY = (targetSize - newHeight) / 2;

//           // Clear the canvas
//           ctx.clearRect(0, 0, targetSize, targetSize);

//           // Draw a circular clipping path
//           ctx.beginPath();
//           ctx.arc(targetSize / 2, targetSize / 2, targetSize / 2, 0, 2 * Math.PI);
//           ctx.clip();

//           // Draw the image on the canvas, centered
//           ctx.drawImage(img, startX, startY, newWidth, newHeight);

//           canvas.toBlob(
//             (blob) => {
//               const resizedFile = new File([blob], file.name, { type: file.type });
//               setProfilePic(resizedFile);

//               const previewURL = URL.createObjectURL(resizedFile);
//               setPreviewUrl(previewURL);
//             },
//             file.type,
//             0.8
//           );
//         };
//         img.src = reader.result;
//       };

//       reader.readAsDataURL(file);
//     } else {
//       setPreviewUrl(null); // Reset preview URL if no file is selected
//     }
//   };

//   const filterPhoneCode = createFilterOptions({
//     stringify: (option) => `${option.countryName} (${option.phoneCode})`,
//   });

//   const onSubmit = handleSubmit(async (data) => {
//     try {
//       const userData = {
//         userName: data.displayName || '',
//         email: data.email || '',
//         mobileNumber: data.phoneNumber || '',
//         mobPhoneCode: data.phncode || null,
//       };

//       const formData = new FormData();
//       formData.append('userD', JSON.stringify(userData));
//       if (profilePic) {
//         formData.append('profileP', profilePic);
//       }
//       formData.append('id', user.userId);

//       await axios.put(
//         `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/updtuserprofile`,
//         formData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'multipart/form-data',
//           },
//         }
//       );

//       enqueueSnackbar('Profile Updated Successfully!');
//     } catch (error) {
//       console.error('Error updating user profile:', error);
//     }
//   });

//   // const onSubmit = handleSubmit(async (data) => {
//   //   try {
//   //     // Simulate delay
//   //     await new Promise((resolve) => setTimeout(resolve, 500));
//   //     enqueueSnackbar('Update success!');
//   //     console.info('DATA', data);
//   //   } catch (error) {
//   //     console.error(error);
//   //   }
//   // });

//   return (
//     <FormProvider methods={methods} onSubmit={onSubmit}>
//       <Grid container spacing={3}>
//         <Grid xs={12} md={4}>
//           {/* {user.employee ? ( */}
//           <Card
//             sx={{
//               pt: 5,
//               pb: 5,
//               px: 3,
//               textAlign: 'center',
//               // // Conditionally apply the sx property
//               // ...(!user.employee ? { display: { xs: 'none', md: 'flex' } } : {}),
//               flexDirection: 'column',
//               alignItems: 'center',
//               justifyContent: 'center',
//             }}
//           >
//             <RHFUploadAvatar
//               name="photoURL"
//               maxSize={3000000}
//               onDrop={handleDrop}
//               file={previewUrl || profilePic}
//               // file={profilePic}
//               helperText={
//                 <Typography
//                   variant="caption"
//                   sx={{
//                     mt: 3,
//                     mx: 'auto',
//                     display: 'block',
//                     textAlign: 'center',
//                     color: 'text.disabled',
//                   }}
//                 >
//                   Allowed *.jpeg, *.jpg, *.png, *.gif,
//                   <br /> max size of {fData(3000000)}
//                 </Typography>
//               }
//             />
//           </Card>
//           {/* ) : ( */}
//           {/* <Card
//               sx={{
//                 pt: 5,
//                 pb: 5,
//                 px: 3,
//                 textAlign: 'center',
//                 // // Conditionally apply the sx property
//                 // ...(!user.employee ? {  } : {}),
//                 display: { xs: 'none', md: 'flex' },
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//             >
//               <img
//                 src="/assets/illustrations/characters/character_9.png"
//                 height="300px"
//                 width="150px"
//                 alt="Placeholder"
//               />
//             </Card>
//           )} */}
//         </Grid>
//         <Grid xs={12} md={8}>
//           <Card sx={{ p: 3 }}>
//             <Box
//               rowGap={3}
//               columnGap={2}
//               display="grid"
//               gridTemplateColumns={{
//                 xs: 'repeat(1, 1fr)',
//                 sm: 'repeat(1, 1fr)',
//               }}
//             >
//               <RHFTextField name="displayName" label="Username" disabled />
//               <RHFTextField name="email" label="Email Address" disabled />
//               <RHFTextField
//                 name="phoneNumber"
//                 label={
//                   <div>
//                     Contact Number <span style={{ color: 'red', fontWeight: 'bolder' }}>*</span>
//                   </div>
//                 }
//                 type="number"
//                 onWheel={(e) => e.target.blur()}
//                 InputLabelProps={{ shrink: true }}
//                 sx={{ width: 310 }}
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">
//                       <Box component="span" sx={{ color: 'text.disabled' }}>
//                         <RHFAutocomplete
//                           name="phncode"
//                           disableClearable
//                           filterOptions={filterPhoneCode}
//                           sx={{
//                             '& fieldset': { border: 'none' },
//                           }}
//                           options={countryArr}
//                           getOptionLabel={(option) => option.phoneCode}
//                           componentsProps={{ popper: { style: { width: 'fit-content' } } }}
//                           renderOption={(props, option) => (
//                             <li {...props} key={option.shortName}>
//                               {option.countryName}({option.phoneCode})
//                             </li>
//                           )}
//                         />
//                       </Box>
//                     </InputAdornment>
//                   ),
//                 }}
//               />

//               <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
//                 <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
//                   Save Changes
//                 </LoadingButton>
//               </Stack>

//               <Box width="80%" marginLeft="70px">
//                 <Card sx={{ p: 3 }}>
//                   <TableContainer component={Paper}>
//                     <Table>
//                       <TableHead>
//                         <TableRow selected={selected}>
//                           <TableCell>Site</TableCell>
//                           <TableCell>Role</TableCell>
//                           {/* <TableCell>Set Default?</TableCell> */}
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         {sites.map((site, index) => (
//                           <TableRow key={index}>
//                             {' '}
//                             {/* Use a unique key for each TableRow */}
//                             <TableCell>{site}</TableCell>
//                             <TableCell>{role[index]}</TableCell>{' '}
//                             {/* Assuming roles is an array with corresponding roles */}
//                             {/* <TableCell padding="checkbox"> */}
//                             {/* <Radio onClick={() => handleSelectRow(index)} />{' '} */}
//                             {/* Assuming handleSelectRow takes an index */}
//                             {/* </TableCell> */}
//                           </TableRow>
//                         ))}
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                 </Card>
//               </Box>

//               {/* <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
//                 <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
//                   Save Changes
//                 </LoadingButton>
//               </Stack> */}
//             </Box>
//           </Card>
//         </Grid>
//       </Grid>
//     </FormProvider>
//   );
// }
// AccountGeneral.propTypes = {
//   onSelectRow: PropTypes.func,
// };
